import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import paths from '../../../assets/paths';

const ProductTitle = ({product, category, size, iconDelay}) => {
  const [imageStyle, setImageStyle] = useState({
    width: size + 'px',
    height: size + 'px',
    minWidth: size + 'px',
    opacity: 0
  });
  const [isTerm] = useState(typeof product === 'string');
  const [viewBox, setViewBox] = useState('0 0 64 64');
  const [path, setPath] = useState(null);

  useEffect(() => {
    let cat = category;
    if (!cat && product && product.product_family) {
      cat = product.product_family;
    }
    if (!cat || !paths.svgPaths.loader[cat]) {
      cat = 'no-category';
    }
    setPath(paths.svgPaths.loader[cat].path);
    setViewBox(`0 0 ${paths.svgPaths.loader[cat].size[0]} ${paths.svgPaths.loader[cat].size[1]}`);

    let timeout = null;
    if (imageStyle.opacity !== 1) {
      if (!iconDelay && imageStyle.opacity !== 1) {
        setImageStyle({...imageStyle, ...{opacity: 1}});
      } else {
        timeout = setTimeout(() => {
          setImageStyle({...imageStyle, ...{opacity: 1}});
        }, iconDelay);
      }
    }

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [imageStyle, path, product, category, iconDelay]);

  return (
    <div className="app-data-block__product-name d-flex">
      {
        !isTerm && (
          <>
            <div className="image" style={imageStyle}>
              <div className="inner">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                  <path d={path}/>
                </svg>
              </div>
            </div>
            <div className="d-flex flex-column pl-4 justify-content-center">
              <div className="title">Product</div>
            </div>
          </>
        )
      }
      {
        isTerm && (
          <>
            <div className="title">{product}</div>
            <div className="model-number">Product Not Found</div>
          </>
        )
      }
    </div>
  );
};

ProductTitle.propTypes = {
  product: PropTypes.object.isRequired,
  category: PropTypes.string,
  size: PropTypes.number.isRequired,
  iconDelay: PropTypes.number,
};

export default ProductTitle;
