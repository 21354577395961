import React from 'react';

import SearchForm from '../../elements/SearchForm/SearchForm';
import HomeFooterBlock from '../../elements/HomeFooterBlock/HomeFooterBlock';
import footerImage1 from '../../../assets/images/icon-analytics.svg'
import footerImage2 from '../../../assets/images/icon-certified.svg'
import footerImage3 from '../../../assets/images/icon-find.svg'

const Home = () => {
  return (
      <div className="app-home d-flex flex-column">
        <SearchForm pulse={true}/>
        <div className="app-home-footer d-flex align-items-center justify-content-center">
          <div className="app-home-footer-inner d-flex align-items-center justify-content-center">
            <HomeFooterBlock image={footerImage1} title="Assess Product Risk"
                             text="Search for a product and see the security risk for free.  Subscribers can also access vulnerabilities and other security data.                             ."/>
            <HomeFooterBlock image={footerImage2} title="Actionable Recommendations"
                             text="Products that have passed independent security lab testing may receive a DeviceLookup recommendation."/>
            <HomeFooterBlock image={footerImage3} title="Coming Soon"
                             text="Contact Us to be notified when DeviceLookup comes out of beta.  Subscribe to keep access to all product security data."/>
          </div>
        </div>
      </div>
  );
};

export default Home;
