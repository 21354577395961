import React, {Component} from 'react';

import SearchForm from '../../elements/SearchForm/SearchForm';
import DataBlock from '../../elements/DataBlock/DataBlock';
import ProductTitle from '../../elements/ProductDataBlock/ProductTitle';
import ProductDetails from '../../elements/ProductDataBlock/ProductDetails';
import ProductVulnerabilityRisk from '../../elements/ProductDataBlock/ProductVulnerabilityRisk';
import ProductVulnerabilities from '../../elements/ProductDataBlock/ProductVulnerabilities';
import ProductTextBox from '../../elements/ProductDataBlock/ProductTextBox';
import ProductTestingVotes from '../../elements/ProductDataBlock/ProductTestingVotes';
import ProductSignUp from '../../elements/ProductDataBlock/ProductSignUp';
import radar from '../../../assets/images/animation/radar.svg';
import radarIE from '../../../assets/images/animation/radarIE.svg';
import classNames from 'classnames';
import paths from '../../../assets/paths';

export default class Product extends Component {
  constructor(props) {
    super(props);

    const timings = {
      define_radar_start_position: 100,
      define_radar_end_position: 100,
      hide_animation_grid: 2700,
      show_product: 4500,
      hide_animation: 5500,
      search_bar_delay: 3000,
      product_icon_delay: 4000,
    };
    timings['item_opacity'] = timings.hide_animation_grid - 100;

    this.state = {
      productId: null,
      term: null,
      product: null,
      loading: false,
      showSignUp: false,
      showLoader: true,
      itemSize: Math.max(Math.min(Math.min(window.innerWidth, window.innerHeight) / 5, 120), 80),
      productStyle: {opacity: 0},
      items: [],
      radarStyle: {},
      itemStyle: {},
      selectedItemStyle: {},
      timings,
      isWide: false,
      currentItemIndex: null
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {match: {params: {productId, term}}} = newProps;
    let id = null;
    if (productId) {
      const parts = productId.split('-');
      id = parts[0];
    }
    if ((id !== this.state.productId || term !== this.state.term) && !this.state.showLoader) {
      this.fetchProduct(id, term);
    }
  }

  componentDidMount() {
    const {match: {params: {productId, term}}} = this.props;
    let id = null;
    if (productId) {
      const parts = productId.split('-');
      id = parts[0];
    }
    this.fetchProduct(id, term);
  }

  componentWillUnmount() {
    let t = setTimeout(() => {});
    while (t--) {
      clearTimeout(t);
    }
  }

  fetchProduct(productId, term) {
    const showAnimation = !!localStorage.getItem('s');
    localStorage.removeItem('s');

    this.setState({
      productId,
      term,
      loading: true,
      product: null,
      productStyle: {opacity: 0},
      showLoader: false,
    });

    if (showAnimation) {
      this.setState({
        showLoader: true,
      });
      setTimeout(() => {
        this.setState({
          itemStyle: {...this.state.itemStyle, opacity: 1, padding: this.state.itemSize / 7.5 + 'px'},
        });
      }, 30);
      setTimeout(() => {
        this.setState({
          radarStyle: {opacity: 1},
        });
      }, 100);
      this.initAnimationItems();
      setTimeout(() => {
        this.changeRadarPosition();
      }, this.state.timings.define_radar_start_position);
    }

    let url = `${process.env.REACT_APP_API_URL}/products/${productId}`;
    if (!productId && term) {
      url = `${process.env.REACT_APP_API_URL}/search/${term}`;
    }

    fetch(url, {headers: {Accept: 'application/json'}})
      .then(response => response.json())
      .then(result => {
        this.setState({loading: false});
        if (result.status === 'error') {
          this.setState({
            radarStyle: {display: 'none'},
            itemStyle: {display: 'none'},
            productStyle: {opacity: 1},
            showLoader: false,
            timings: {
              ...this.state.timings,
              ...{item_opacity: 1000, search_bar_delay: 0}
            }
          });
        } else if (result.data) {
          const category = result.data.product_family && paths.svgPaths.loader[result.data.product_family] ? result.data.product_family : 'no-category';
          this.setState({product: result.data, category});
          if (showAnimation) {
            setTimeout(() => {
              this.changeRadarPosition(true);
            }, this.state.timings.define_radar_end_position);
            setTimeout(() => {
              const image = document.getElementsByClassName('image').item(0);
              if (image) {
                const container = document.getElementsByClassName('app-product').item(0);
                this.setState({
                  selectedItemStyle: {
                    ...this.state.selectedItemStyle,
                    position: 'absolute',
                    left: image.offsetLeft + container.offsetLeft + (window.innerWidth <= 575 ? 8 : 0) + 'px',
                    top: image.offsetTop + container.offsetTop - 6 + 'px',
                    width: '100px',
                    height: '100px',
                    padding: 0
                  },
                });
              }
            }, this.state.timings.hide_animation_grid);
            setTimeout(() => {
              this.setState({
                itemStyle: {...this.state.itemStyle, opacity: 0},
              });
            }, this.state.timings.hide_animation_grid - 400);
            setTimeout(() => {
              this.setState({
                radarStyle: {...this.state.radarStyle, opacity: 0},
              });
            }, this.state.timings.hide_animation_grid - 700);
            setTimeout(() => {
              this.setState({
                radarStyle: {display: 'none'},
                itemStyle: {display: 'none'}
              });
            }, this.state.timings.hide_animation_grid + 300);
            setTimeout(() => {
              this.setState({showLoader: false});
            }, this.state.timings.hide_animation);
          } else {
            this.setState({
              timings: {
                ...this.state.timings, ...{
                  show_product: 1000,
                  search_bar_delay: 0,
                  product_icon_delay: 0
                }
              }
            })
          }
          setTimeout(() => {
            this.setState({productStyle: {opacity: 1}});
          }, this.state.timings.show_product);
        }
      })
      .catch(() => {
        this.setState({loading: false, showLoader: false, productStyle: {opacity: 1}});
      });
  }

  initAnimationItems() {
    const rows = Math.floor((window.innerHeight) / this.state.itemSize) + 3;
    const cols = Math.floor(window.innerWidth / this.state.itemSize) + 3;
    const items = [];

    let index = null;
    let category = null;
    let tries = 0;
    let previousCategories = [];
    const allPaths = {...paths.svgPaths.loader};
    for (let i = 0; i < rows; i++) {
      items.push([]);
      for (let j = 0; j < cols; j++) {
        tries = 0;
        const pathsObject = j < 3 && Object.keys(allPaths).length ? allPaths : paths.svgPaths.loader;
        do {
          previousCategories = [];
          index = Math.floor(Math.random() * Object.keys(pathsObject).length);
          category = Object.keys(pathsObject)[index];
          if (i) {
            previousCategories.push(items[i - 1][j].category);
          }
          if (j) {
            previousCategories.push(items[i][j - 1].category);
          }
        } while (previousCategories.includes(category) && tries++ <= 50);

        items[i].push({
          index,
          path: pathsObject[category].path,
          size: pathsObject[category].size,
          category
        });

        if (j < 3 && Object.keys(allPaths).length) {
          delete allPaths[category];
        }
      }
    }
    this.setState({
      items,
      itemStyle: {
        width: this.state.itemSize + 8 + 'px',
        height: this.state.itemSize + 8 + 'px',
        animationDuration: this.state.timings.item_opacity + 'ms'
      },
    });
  }

  changeRadarPosition(highlight = false) {
    const item = this.getRandomItem(highlight);
    if (item) {
      if (highlight) {
        const index = item.getAttribute('data-index').split('-');
        const items = this.state.items;
        if (items[index[0]] && items[index[0]][index[1]]) {
          items[index[0]][index[1]].highlight = true;
          this.setState({items});
        }
        this.setState({
          currentItemIndex: index,
          selectedItemStyle: {
            ...this.state.itemStyle, ...{
              left: item.offsetLeft + 'px',
              top: item.offsetTop + 'px'
            }
          },
        });
      }
      this.setState({
        radarStyle: {
          display: 'block',
          left: item.offsetLeft + ((this.state.itemSize + 8) / 2) - (this.state.itemSize * 5 / 2) + 'px',
          top: item.offsetTop + ((this.state.itemSize + 8) / 2) - (this.state.itemSize * 5 / 2) + 80 + 'px',
          width: this.state.itemSize * 5 + 'px',
          height: this.state.itemSize * 5 + 'px',
          opacity: 1
        },
      });
    }
  };

  getRandomItem() {
    let className = 'item';
    if (this.state.category) {
      className += ' ' + this.state.category
    }
    let items = document.getElementsByClassName(className);
    if (!items.length) {
      items = document.getElementsByClassName('item no-category');
    }
    let tries = 0;
    let item = null;
    let index = null;

    if (items.length) {
      do {
        item = items.item(Math.floor(Math.random() * items.length));
        index = item.getAttribute('data-index');
      } while (
        (
          parseInt(index.split('-')[0]) >= this.state.items.length - 5 ||
          parseInt(index.split('-')[1]) >= this.state.items[0].length - 5 ||
          index === this.state.currentItemIndex
        )
        && tries++ <= 50);
    }

    return item;
  };

  render() {
    let bountyText = null;
    if (this.state.product && this.state.product.bounty) {
      bountyText = 'Manufacturer runs a bug bounty program that has ' + (this.state.product.bounty === 1 ? 'some' : 'high') + ' participation.';
    }

    let descriptionText = null;
    if (!bountyText) {
      descriptionText = 'This product has not been tested and the manufacturer does not have an effective bug bounty program. ' +
        'Two 2019 studies have shown that 60-70% of devices on the market are vulnerable, so proceed with extreme caution.';
    }

    const hasVulnerabilities = this.state.product && this.state.product.vulnerabilities && !!this.state.product.vulnerabilities.length;

    let content = null;
    if (!this.state.loading) {
      if (!this.state.product) {
        // Product not found
        content = (
          <>
            <div className="row no-gutters">
              <div className="col-12 col-md-7 pr-md-2">
                <DataBlock content={
                  <div className="app-data-block__product-name">
                    <div className="title">{this.state.term}</div>
                    <div className="model-number full">Product Not Found</div>
                  </div>
                } additionalClass={'product-name'}/>
              </div>
              <div className="col-12 col-md-5 pl-md-2 pt-3 pt-md-0">
                <DataBlock content={<ProductVulnerabilityRisk/>}
                           help="This product has not been tested by an independent cybersecurity lab.
                           This risk assessment is based on publicly available information on the product."
                           additionalClass={'product-vulnerability-risk'}/>
              </div>
            </div>
            <div className="row no-gutters mt-3">
              <div className="col-12">
                <DataBlock help="The most secure products are tested by an independent security lab."
                           vreduced={true}
                           additionalClass={'product-text-box'}
                           content={
                             <ProductTextBox
                               text="This product has not been tested by an independent cybersecurity lab."/>
                           }/>
              </div>
            </div>
            <div className="row no-gutters mt-3">
              <div className="col-12">
                <DataBlock content={
                  <div className="app-product__error d-flex align-items-center">
                    <div className="caution-icon d-flex align-items-center justify-content-center mr-3">
                      <span>!</span>
                    </div>
                    <div className="description">{descriptionText}</div>
                  </div>
                } additionalClass={'product-details'}/>
              </div>
            </div>
          </>
        );
      } else if (this.state.product.id) {
        // Product found
        content = (
          <div className="row no-gutters">
            <div className="can-be-wide">
              <div className="row no-gutters">
                <div className="col-12 col-md-7 pr-md-2">
                  <div className="row h-100">
                    <div className="col-12">
                      <DataBlock content={
                        <div className="d-flex flex-column">
                          <ProductTitle product={this.state.product}
                                        category={this.state.category}
                                        iconDelay={this.state.timings.product_icon_delay}
                                        size={100}/>
                          <div className="mt-3">
                            <ProductDetails product={this.state.product}/>
                          </div>
                        </div>
                      } additionalClass={'product-name'}/>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 pl-md-2 pt-3 pt-md-0">
                  <DataBlock content={<ProductVulnerabilityRisk product={this.state.product}/>}
                             help="This product has not been tested by an independent cybersecurity lab.
                               This risk assessment is based on publicly available information on the product."
                             additionalClass={'product-vulnerability-risk'}/>
                </div>
              </div>
              <div className="row no-gutters mt-3">
                <div className="col-12">
                  <DataBlock help="The most secure products are tested by an independent security lab.
                                   If you would like this product to be tested, click the button below."
                             vreduced={true}
                             additionalClass={'product-testing-votes'}
                             content={<ProductTestingVotes product={this.state.product}/>}
                  />
                </div>
              </div>
              {
                bountyText && (
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <DataBlock content={<ProductTextBox text={bountyText}/>}
                                 vreduced={true}
                                 additionalClass={'product-text-box'}
                                 help="Manufacturer bug bounty programs reward responsible hackers for finding vulnerabilities in a manufacturer’s products.
                                   This allows the manufacturer to find problems before the bad guys do.
                                   The best programs give high rewards and have high engagement, which in turn reduces cybersecurity risk."/>
                    </div>
                  </div>
                )
              }
              {
                descriptionText && (
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <DataBlock content={
                        <div className="app-product__error d-flex align-items-center">
                          <div className="caution-icon d-flex align-items-center justify-content-center mr-3">
                            <span>!</span>
                          </div>
                          <div className="description">{descriptionText}</div>
                        </div>
                      } additionalClass={'product-details'}/>
                    </div>
                  </div>
                )
              }
              {
                hasVulnerabilities && (
                  <div className="row no-gutters mt-3 hide-on-wide">
                    <div className="col-12">
                      <DataBlock content={<ProductVulnerabilities product={this.state.product}/>}
                                 additionalClass={'product-vulnerabilities'}/>
                    </div>
                  </div>
                )
              }
              {
                this.state.showSignUp && (
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <DataBlock content={<ProductSignUp/>} additionalClass={'product-sign-up'}/>
                    </div>
                  </div>
                )
              }
            </div>
            {
              hasVulnerabilities && (
                <div className="col-6">
                  <div className="row no-gutters pl-2 show-on-wide">
                    <div className="col-12">
                      <DataBlock content={<ProductVulnerabilities product={this.state.product}/>}
                                 additionalClass={'product-vulnerabilities'}/>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        );
      } else {
        // Company found by term, no product
        content = (
          <div className="row no-gutters">
            <div className="col-12">
              <div className="row no-gutters">
                <div className="col-12 col-md-7 pr-md-2">
                  <div className="row h-100">
                    <div className="col-12">
                      <DataBlock content={
                        <div className="d-flex flex-column">
                          <ProductTitle product={this.state.product}
                                        category={this.state.category}
                                        size={100}/>
                          <div className="mt-3">
                            <ProductDetails product={this.state.product}/>
                          </div>
                        </div>
                      } additionalClass={'product-name'}/>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 pl-md-2 pt-3 pt-md-0">
                  <DataBlock content={<ProductVulnerabilityRisk product={this.state.product}/>}
                             help="This product has not been tested by an independent cybersecurity lab.
                               This risk assessment is based on publicly available information on the product."
                             additionalClass={'product-vulnerability-risk'}/>
                </div>
              </div>
              <div className="row no-gutters mt-3">
                <div className="col-12">
                  <DataBlock help="The most secure products are tested by an independent security lab."
                             vreduced={true}
                             additionalClass={'product-text-box'}
                             content={
                               <ProductTextBox
                                 text="This product has not been tested by an independent cybersecurity lab."/>
                             }/>
                </div>
              </div>
              {
                bountyText && (
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <DataBlock content={<ProductTextBox text={bountyText}/>}
                                 vreduced={true}
                                 additionalClass={'product-text-box'}/>
                    </div>
                  </div>
                )
              }
              {
                descriptionText && (
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <DataBlock content={
                        <div className="app-product__error d-flex align-items-center">
                          <div className="caution-icon d-flex align-items-center justify-content-center mr-3">
                            <span>!</span>
                          </div>
                          <div className="description">{descriptionText}</div>
                        </div>
                      } additionalClass={'product-details'}/>
                    </div>
                  </div>
                )
              }
              {
                this.state.showSignUp && (
                  <div className="row no-gutters mt-3">
                    <div className="col-12">
                      <DataBlock content={<ProductSignUp/>} additionalClass={'product-sign-up'}/>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        );
      }
    }

    const containerClasses = {
      'loader-animation': true,
      finished: !this.state.showLoader,
      hidden: this.state.itemStyle && (this.state.itemStyle.opacity === 0 || this.state.itemStyle.display === 'none'),
      wide: hasVulnerabilities
    };

    return (
      <>
        <div className={classNames(containerClasses)}>
          <div className="app-product container p-0" style={this.state.productStyle}>
            {/* Top search bar */}
            <div className="row no-gutters">
              <div className="col-12">
                <SearchForm simple={true} delay={this.state.timings.search_bar_delay}/>
              </div>
            </div>
            {/* Product information */}
            <div className="row no-gutters mt-4">
              <div className="col-12">
                {content}
              </div>
            </div>
          </div>
          {
            // Loader animation
            this.state.showLoader && (
              <>
                {
                  (!!document.documentMode || !!window.StyleMedia) && (
                    <img className="radar-image" src={radarIE} alt="" style={this.state.radarStyle}/>
                  )
                }
                {
                  !document.documentMode && !window.StyleMedia && (
                    <img className="radar-image" src={radar} alt="" style={this.state.radarStyle}/>
                  )
                }
                <div className="items-container">
                  {
                    this.state.items.map((row, rowIndex) => {
                      return (
                        <div className="row no-gutters" key={rowIndex}>
                          {
                            row.map((item, itemIndex) => {
                              const classList = {
                                item: true,
                                highlight: item.highlight
                              };
                              classList[item.category] = true;
                              return (
                                <div key={rowIndex + '-' + itemIndex}
                                     data-index={rowIndex + '-' + itemIndex}
                                     data-category={item.category}
                                     className={classNames(classList)}
                                     style={item.highlight ? this.state.selectedItemStyle : this.state.itemStyle}>
                                  <div className="inner">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox={`0 0 ${item.size[0]} ${item.size[1]}`}>
                                      <path d={item.path}/>
                                    </svg>
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      );
                    })
                  }
                </div>
              </>
            )
          }
        </div>
      </>
    );
  }
}
