import React from 'react';
import PropTypes from 'prop-types';

const ProductTextBox = ({text}) => {
  return (
    <div className="app-data-block__product-text-box py-2">
      {text}
    </div>
  );
};

ProductTextBox.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ProductTextBox;
