import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import Autosuggest from 'react-autosuggest';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';

import searchIcon from '../../../assets/icons/search-icon.svg';
import loaderIcon from '../../../assets/icons/loader.svg';

const AbortController = window.AbortController;

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      searching: false,
      abortController: null
    };
  }

  async getSuggestions(value) {
    if (value.length < 2) {
      return [];
    }
    if (this.state.abortController) {
      this.state.abortController.abort();
    }
    this.state.abortController = new AbortController();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/search?q=${value}`, {
      signal: this.state.abortController.signal
    }).catch(e => {
    });
    if (response && response.ok) {
      const results = await response.json();
      const suggestions = [];
      if (!results.exact_match) {
        suggestions.push(value);
      }
      if (results.data) {
        results.data.forEach(product => {
          suggestions.push({...product});
        });
      }
      return suggestions;
    }
    return null;
  };

  getSuggestionValue(suggestion) {
    if (suggestion && suggestion.name) {
      return suggestion.company ? suggestion.company + ' ' + suggestion.name : suggestion.name;
    } else if (suggestion) {
      return suggestion;
    }
    return this.state.value;
  }

  matchSuggestion(value, query) {
    const index = value.toLowerCase().indexOf(query.toLowerCase());
    if (index > -1) {
      return [[index, index + query.length]];
    }
    return [];
  }

  renderSuggestion(suggestion, {query}) {
    let parts = [];
    let text = query;
    let right = null;
    if (suggestion && suggestion.name) {
      text = suggestion.name;
      if (suggestion.company) {
        text = suggestion.company + ' ' + text;
      }
      parts = parse(text, this.matchSuggestion(text, query));
      if (suggestion.category) {
        right = (
          <div className='app-search-form-result-item-right'>
            {suggestion.category}
          </div>
        );
      }
    } else {
      parts = [{text: query}];
    }

    const itemClassList = {
      'app-search-form-result-item': true,
      selected: parts.map(p => p.text).join('') === this.state.value
    };

    return (
      <div className={classNames(itemClassList)} title={text}>
        <div className="app-search-form-result-item-left">
          {
            parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;
              return (
                <span className={className} key={index}>{part.text}</span>
              );
            })
          }
        </div>
        {right}
      </div>
    );
  };

  onSuggestionsFetchRequested({value, reason}) {
    if (reason === 'input-focused') {
      return;
    }
    this.setState({searching: true});
    this.getSuggestions(value, reason === 'input-focused').then(suggestions => {
      this.setState({searching: false});
      if (suggestions) {
        this.setState({suggestions});
      }
    });
  };

  onSuggestionsClearRequested() {
  };

  onChange(event, {newValue}) {
    this.setState({value: newValue});
  };

  onKeyDown(event) {
    if (
      event.which === 13
      && this.state.value.length >= 2
      && this.state.suggestions.length
      && typeof this.state.suggestions[0] === 'string'
      && this.state.value === this.state.suggestions[0]
    ) {
      this.onSuggestionSelected(null, {suggestion: this.state.suggestions[0]});
    }
  };

  onSuggestionSelected(event, {suggestion}) {
    if (suggestion !== this.state.value) {
      this.setState({suggestions: this.state.suggestions.filter(s => typeof s !== 'string')});
    }
    if (suggestion) {
      if (!this.props.simple) {
        localStorage.setItem('s', 'true');
      }
      this.props.history.push(suggestion.id ? `/product/${suggestion.id}-${suggestion.company}-${suggestion.name}` : `/search/${suggestion}`);
    }
  };

  render() {
    const {value, suggestions, searching} = this.state;
    const {simple, pulse, delay} = this.props;

    const inputProps = {
      placeholder: 'Search for product name or manufacturer',
      value,
      onChange: this.onChange.bind(this),
      onKeyDown: this.onKeyDown.bind(this)
    };

    const loader = searching ? (<img className="loader-icon" src={loaderIcon} alt="" width="24"/>) : '';

    const componentClasses = {
      'app-search-form': true,
      'app-search-form-simple': !!simple,
      'app-search-form-pulse': !!pulse,
      'searching': searching,
      'nothing-found': !searching && value.length > 2 && !suggestions.length
    };
    const style = {};
    if (delay !== undefined) {
      style['animationDelay'] = delay + 'ms';
    }

    return (
      <div className={classNames(componentClasses)} style={style}>
        <div className="app-search-form-outer">
          <img className="search-icon" src={searchIcon} alt="" width="24"/>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
            getSuggestionValue={this.getSuggestionValue.bind(this)}
            renderSuggestion={this.renderSuggestion.bind(this)}
            onSuggestionSelected={this.onSuggestionSelected.bind(this)}
            inputProps={inputProps}
          />
          {loader}
        </div>
      </div>
    );
  }
}

export default withRouter(SearchForm);

SearchForm.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  simple: PropTypes.bool,
  pulse: PropTypes.bool,
  delay: PropTypes.number
};
