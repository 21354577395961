import React from 'react';
import PropTypes from 'prop-types';

const ProductDetails = ({product}) => {
  return (
      <div className="app-data-block__product-details">
        <div className="title">{product.company}</div>
        <div className="description mt-1">{product.name}</div>
      </div>
  );
};

ProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductDetails;
