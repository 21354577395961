import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse'
import flash from '../../../assets/icons/flash.svg';

const ProductVulnerabilities = React.memo(({product}) => {
  const [vulnerabilities, setVulnerabilities] = useState([]);

  const expand = id => {
    setVulnerabilities(vulnerabilities.map(v => {
      if (v.cve_id === id) {
        v.open = !v.open;
      }
      return v;
    }));
  };

  const expandDesc = (id, idx) => {
    setVulnerabilities(vulnerabilities.map(v => {
      if (v.cve_id === id) {
        v.description[idx].open = !v.description[idx].open;
      }
      return v;
    }));
  };

  useEffect(() => {
    const vuls = product.vulnerabilities && product.vulnerabilities.length ? product.vulnerabilities.map(vul => {
      return {...vul};
    }) : [];
    if (!vulnerabilities.length) {
      setVulnerabilities(vuls.map((vul, index) => {
        vul.open = !index || !vul.fixed;
        vul.severity = vul.severity.substr(0, 1).toUpperCase() + vul.severity.substr(1).toLowerCase();
        vul.reportersInline = vul.reporters ? vul.reporters.join(', ') : null;
        if (vul.description && Array.isArray(vul.description) && vul.description.length) {
          vul.description = vul.description.map(desc => {
            return {
              text: desc,
              openable: desc.length > 180,
              open: desc.length <= 180
            }
          });
        }

        return vul;
      }));
    }

    return function cleanup() {
    };
  }, [product, vulnerabilities]);

  const request = (company, product, cve) => {
    window.open("https://www.wivity.com/remediate-request-form?company=" + company + "&product=" + product + "&cve=" + cve, "_blank");
  };

  return (
    <div className="app-data-block__product-vulnerabilities d-flex flex-column">
      <div className="title text-center mb-2">Vulnerabilities Found</div>
      {
        !!vulnerabilities && vulnerabilities.map((vul, index) => {
          if (vul.fixed && index > 2) {
            return null;
          } else {
            const classList = {
              'vulnerability-expand': true,
              'd-flex': true,
              expanded: vul.open
            };

            return (
              <div className="product-vulnerability" key={index}>
                <div className={classNames(classList)}
                    onClick={() => expand(vul.cve_id)}
                    aria-controls={'vulnerability-details-' + index}
                    aria-expanded={vul.open}
                >
                  <div>{vul.cve_id}: {vul.severity} Severity</div>
                  {
                    vul.fixed
                      ? <div className="vulnerability-fixed ml-auto">Fixed</div>
                      : <div className="vulnerability-not-fixed ml-auto">Not Fixed</div>
                  }
                </div>
                <Collapse in={vul.open}>
                  <div className="vulnerability-details" id={'vulnerability-details-' + index}>
                    {
                      !!vul.description && Array.isArray(vul.description) && vul.description.map((desc, idx) => {
                        const descClasses = {
                          'vulnerability-description': true,
                          collapsed: !desc.open
                        };
                        return <div key={vul.cve_id + '-' + idx}
                                    className={classNames(descClasses)}>
                          &ndash; {desc.text}
                          {
                            desc.openable && (
                              <button onClick={() => expandDesc(vul.cve_id, idx)}>
                                {
                                  desc.open ? 'Show less' : 'Show more'
                                }
                              </button>
                            )
                          }

                        </div>;
                      })
                    }
                    {
                      vul.reportersInline && (
                        <div className="mt-2">&ndash; Reported by {vul.reportersInline}</div>
                      )
                    }
                    {
                      vul.confirmed && (
                        <div className="mt-2">&ndash; Confirmed by {product.company}</div>
                      )
                    }
                    {
                      vul.published && (
                        <div className="mt-2">&ndash; Published
                          on {(new Date(vul.published)).toLocaleDateString()}</div>
                      )
                    }
                    {
                      vul.fixed ? null : (
                        <div className="d-flex justify-content-end app-data-block__request-fix">
                          <button className="btn d-flex align-items-center" onClick={() => request(product.company, product.name, vul.cve_id)} >
                            <img src={flash} alt="" className="mr-2"/>
                              REQUEST REMEDIATION
                          </button>
                        </div>
                      )
                    }
                  </div>
                </Collapse>
              </div>
            )
          }
        }).filter(Boolean)
      }
    </div>
  );
});

ProductVulnerabilities.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductVulnerabilities;
