import React from 'react';
import SearchForm from '../../elements/SearchForm/SearchForm';

const Category = () => {
  return (
      <div className="app-category container p-0">
        <div className="row no-gutters">
          <div className="col-12">
            <SearchForm simple={true}/>
          </div>
        </div>
        <div className="row no-gutters mt-4">
          <div className="col-12 text-center">
            In Development.
          </div>
        </div>
      </div>
  );
};

export default Category;