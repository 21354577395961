import React, {Component} from 'react';
import {createBrowserHistory} from 'history';
import {Route, Router, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';

import 'bootstrap/dist/css/bootstrap.min.css';

import './scss/App.scss';
import Page from './components/layout/Page/Page';
import Home from './components/pages/Home/Home';
import Product from './components/pages/Product/Product';
import Category from './components/pages/Category/Category';
import NotFound from './components/pages/NotFound/NotFound';

class App extends Component {
  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      this.history.listen(location => {
        ReactGA.set({page: location.pathname});
        ReactGA.pageview(location.pathname);
      });
    }
  }

  render() {
    return (
      <Router history={this.history}>
        <Page>
          <Switch>
            <Route exact strict path="/" component={Home}/>
            <Route exact strict path="/product/:productId" component={Product}/>
            <Route exact strict path="/search/:term" component={Product}/>
            <Route exact strict path="/category/:categoryId" component={Category}/>
            <Route component={NotFound}/>
          </Switch>
        </Page>
      </Router>
    );
  }
}

export default App;
