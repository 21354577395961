import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import flash from '../../../assets/icons/flash.svg';

const ProductTestingVotes = ({product}) => {
  const [votes, setVotes] = useState(0);
  const [voted, setVoted] = useState([]);

  const request = () => {
    if (product.id) {
      if (voted.indexOf(product.id) > -1) {
        return;
      }
      setVoted(voted.concat([product.id]));
      fetch(`${process.env.REACT_APP_API_URL}/products/${product.id}`, {method: 'POST'})
        .then(response => response.text())
        .then(() => setVotes(votes + 1))
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (product && product.test_votes) {
      setVotes(product.test_votes);
    }
    if (localStorage.getItem('voted')) {
      setVoted(JSON.parse(localStorage.getItem('voted')));
    }
  }, [product]);

  useEffect(() => {
    localStorage.setItem('voted', JSON.stringify(voted));
  }, [voted]);

  return (
    <div className="app-data-block__testing-votes app-data-block__product-text-box d-flex flex-column">
      <div className="py-2">
        This product has not been tested by an independent cybersecurity lab.
      </div>
      {
        !!product && !!product.id && (
          <div className="d-flex justify-content-end">
            <button className="btn d-flex align-items-center" onClick={() => request()} disabled={voted.indexOf(product.id) > -1}>
              <img src={flash} alt="" className="mr-2"/>
              VOTE FOR TESTING
              {
                !!votes && (
                  <span className="badge badge-light ml-2 d-flex align-items-center justify-content-center">{votes}</span>
                )
              }
            </button>
          </div>
        )
      }
    </div>
  );
};

ProductTestingVotes.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductTestingVotes;
