import React from 'react';

const HomeFooterBlock = ({ image, title, text }) => {
  return (
    <div className="app-home-footer-block">
      <div className="app-home-footer-block-image">
        <img src={image} alt="" />
      </div>
      <div className="app-home-footer-block-text">
        <div className="title">{title}</div>
        <div className="description">{text}</div>
      </div>
    </div>
  );
};

export default HomeFooterBlock;