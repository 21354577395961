import React from 'react';
import {Link} from 'react-router-dom';

import logo from '../../../assets/images/wivity-logo.png';

const Header = () => {
  return (
    <div className="app-header">
      <a href="https://www.wivity.com/aboutus/" target="_blank" rel="noopener noreferrer" className="header-link">Contact Us</a>
      <Link className="app-header-logo" to="/">
        <img src={logo} alt="Wivity"/>
      </Link>
    </div>
  );
};

export default Header
