import React from 'react';

import Header from '../Header/Header';

const Page = ({children}) => {
  return (
      <div className="app">
        <Header/>
        <div className="app-container">
          {children}
        </div>
      </div>
  );
};

export default Page;
