import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProductVulnerabilityRisk = ({product}) => {
  const [riskStyle, setRiskStyle] = useState({height: '0%', top: '100%'});
  const [valueClasses, setValueClasses] = useState({});

  useEffect(() => {
    const risk = product && product.risk !== null && product.risk !== undefined ? product.risk : 2;
    setValueClasses({
      'vulnerability-risk-marker-value': true,
      low: risk === 0,
      medium: risk === 1,
      high: risk === 2,
    });
    const timeout = setTimeout(() => {
      setRiskStyle({
        height: (Math.min(risk * 100 + (!risk ? 30 : 0), 200) / 2) + '%',
        top: 100 - (Math.min(risk * 100 + (!risk ? 30 : 0), 200) / 2) + '%'
      });
    }, 2500);

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [product]);

  return (
    <div className="app-data-block__product-vulnerability-risk d-flex flex-column align-items-center">
      <div className="title">Security Risk Level</div>
      <div className="vulnerability-risk d-flex">
        <div className="col-6 d-flex justify-content-end justify-content-md-center">
          <div className="vulnerability-risk-marker"/>
          <div className={classNames(valueClasses)} style={riskStyle}/>
        </div>
        <div className="col-6 d-flex flex-column justify-content-between align-items-start align-items-md-center py-2">
          <div>HIGH</div>
          <div>LOW</div>
        </div>
      </div>
    </div>
  );
};

ProductVulnerabilityRisk.propTypes = {
  product: PropTypes.object,
};

export default ProductVulnerabilityRisk;
