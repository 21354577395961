import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => {
  return (
      <div className="app-not-found d-flex flex-column align-items-center justify-content-center">
        <div className="mb-2">Page not found</div>
        <Link to="/">Go to the home page</Link>
      </div>
  );
};

export default NotFound;