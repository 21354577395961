module.exports = {
  svgPaths: {
    loader: {
      'no-category': {
        path: 'M17.5 51.134c2.147 0 3.889 1.761 3.889 3.933 0 2.171-1.742 3.933-3.889 3.933s-3.889-1.762-3.889-3.933c0-2.172 1.742-3.933 3.889-3.933zM17.5 0C27.149 0 35 7.942 35 17.7c0 5.314-4.558 13.126-11.344 16.292a3.954 3.954 0 0 0-2.267 3.576v1.766c0 2.17-1.739 3.933-3.889 3.933-2.15 0-3.889-1.762-3.889-3.933v-1.766c0-4.59 2.668-8.799 6.786-10.723 3.963-1.844 6.825-6.742 6.825-9.145 0-5.42-4.36-9.833-9.722-9.833-5.363 0-9.722 4.413-9.722 9.833 0 2.171-1.739 3.934-3.89 3.934C1.739 21.634 0 19.87 0 17.7 0 7.942 7.851 0 17.5 0z',
        size: [35, 59]
      },
      auto: {
        path: 'M32.2 0C14.514 0 0 14.514 0 32.2c0 17.686 14.514 32.2 32.2 32.2 17.686 0 32.2-14.514 32.2-32.2C64.4 14.514 49.886 0 32.2 0zm0 7.547c11.124 0 20.653 7.576 23.645 17.744a1.871 1.871 0 0 0-.956-1.025c-5.2-2.406-13.46-5.273-22.689-5.273-9.23 0-17.49 2.867-22.69 5.273a1.871 1.871 0 0 0-.955 1.025C11.547 15.123 21.076 7.547 32.2 7.547zM8.034 37.057a1.886 1.886 0 0 0 1.556 1.384l4.533.63c5.773.823 10.383 5.433 11.206 11.206l.63 4.533a1.872 1.872 0 0 0 1.115 1.446c-9.496-2.037-17.115-9.661-19.04-19.198zm24.166.803a5.666 5.666 0 0 1-5.66-5.66 5.666 5.666 0 0 1 5.66-5.66 5.666 5.666 0 0 1 5.66 5.66 5.666 5.666 0 0 1-5.66 5.66zm5.126 18.395a1.872 1.872 0 0 0 1.115-1.445l.63-4.533c.823-5.773 5.433-10.383 11.206-11.206l4.533-.63a1.887 1.887 0 0 0 1.556-1.383c-1.925 9.537-9.544 17.16-19.04 19.197z',
        size: [65, 65]
      },
      camera: {
        path: 'M37.542 46.2l4.76 7.84c0 .84-.56 1.96-1.68 1.96h-36.4c-1.12 0-1.68-1.12-1.12-1.96l4.76-7.84c4.2 2.8 9.24 4.2 14.84 4.2 5.32 0 10.36-1.4 14.84-4.2zM22.422 0c12.32 0 22.4 10.08 22.4 22.4 0 12.32-10.08 22.4-22.4 22.4-12.32 0-22.4-10.08-22.4-22.4 0-12.32 10.08-22.4 22.4-22.4zm0 11.2c-6.16 0-11.2 5.04-11.2 11.2 0 6.16 5.04 11.2 11.2 11.2 6.16 0 11.2-5.04 11.2-11.2 0-6.16-5.04-11.2-11.2-11.2zm0 5.6c3.08 0 5.6 2.52 5.6 5.6 0 3.08-2.52 5.6-5.6 5.6-3.08 0-5.6-2.52-5.6-5.6 0-.56 0-1.12.28-1.4 2.24 1.4 5.32-1.4 3.92-3.92.28-.28.84-.28 1.4-.28z',
        size: [45, 56]
      },
      drone: {
        path: 'M60.003 45.652a2.342 2.342 0 0 1 3.31 3.312l-4.699 4.698a4.203 4.203 0 0 1-1.154 3.8l-.186.175a4.201 4.201 0 0 1-3.613.979l-4.7 4.698a2.342 2.342 0 0 1-3.31-3.312l4.7-4.698a4.202 4.202 0 0 1 1.153-3.8 4.201 4.201 0 0 1 3.8-1.154zM.686 45.651a2.342 2.342 0 0 1 3.311 0l4.7 4.7a4.21 4.21 0 0 1 4.95 4.95l4.702 4.702a2.341 2.341 0 1 1-3.311 3.31l-4.703-4.701a4.208 4.208 0 0 1-4.947-4.948L.686 48.962a2.341 2.341 0 0 1 0-3.311zm41.059-31.848a5.153 5.153 0 0 1 7.279 0l1.174 1.175a5.144 5.144 0 0 1 0 7.279l-3.676 3.676a3.248 3.248 0 0 0-.958 2.312v7.51c0 .874.34 1.695.958 2.313l3.676 3.676a5.144 5.144 0 0 1 0 7.278l-1.174 1.175a5.152 5.152 0 0 1-7.28 0l-3.675-3.676a3.249 3.249 0 0 0-2.312-.958h-7.511c-.873 0-1.695.34-2.312.958l-3.676 3.676a5.153 5.153 0 0 1-7.279 0l-1.175-1.175a5.153 5.153 0 0 1 0-7.279l3.676-3.676a3.248 3.248 0 0 0 .958-2.312v-7.51c0-.874-.34-1.695-.958-2.312l-3.676-3.676a5.153 5.153 0 0 1 0-7.28l1.175-1.174a5.153 5.153 0 0 1 7.279 0l3.676 3.676a3.249 3.249 0 0 0 2.312.958h7.51c.874 0 1.695-.34 2.312-.958zM35.035 29.66h-6.07a2.342 2.342 0 0 0 0 4.683h6.07a2.342 2.342 0 0 0 0-4.683zM45.652.687a2.342 2.342 0 0 1 3.311 0l4.701 4.7a4.212 4.212 0 0 1 3.798 1.153 4.212 4.212 0 0 1 1.151 3.797l4.701 4.7a2.341 2.341 0 1 1-3.311 3.312l-4.7-4.7a4.21 4.21 0 0 1-4.95-4.95l-4.7-4.7a2.341 2.341 0 0 1 0-3.312zM15.037.686a2.342 2.342 0 0 1 3.312 3.31L13.65 8.7a4.21 4.21 0 0 1-4.952 4.95L4 18.35a2.341 2.341 0 1 1-3.312-3.311l4.7-4.702a4.21 4.21 0 0 1 4.952-4.95z',
        size: [64, 64]
      },
      'generic-electronic': {
        path: 'M36 16.175a3.61 3.61 0 0 1 3.61 3.609v13.46c0 7.463-4.835 13.819-11.536 16.101l.463 2.3a4.802 4.802 0 0 1-1.095 4.123c-.93 1.066-2.299 1.647-3.714 1.647h-2.119v2.38a1.805 1.805 0 0 1-1.892 1.803c-.97-.045-1.717-.882-1.717-1.853v-2.33h-2.204a4.802 4.802 0 0 1-3.825-1.887c-.862-1.123-1.162-2.58-.883-3.967l.447-2.216C4.834 47.063 0 40.707 0 33.244v-13.46a3.61 3.61 0 0 1 3.61-3.61zM19.805 31.934a5.943 5.943 0 0 0-5.937 5.937 5.943 5.943 0 0 0 5.937 5.937 5.944 5.944 0 0 0 5.936-5.937 5.944 5.944 0 0 0-5.936-5.937zm0 3.61a2.33 2.33 0 0 1 2.327 2.327 2.33 2.33 0 0 1-2.327 2.327 2.33 2.33 0 0 1-2.328-2.327 2.33 2.33 0 0 1 2.328-2.328zM12.209 0c.997 0 1.805.808 1.805 1.805v10.76h-3.61V1.805C10.404.808 11.213 0 12.21 0zm15.19 0c.998 0 1.805.808 1.805 1.805v10.76h-3.609V1.805C25.595.808 26.403 0 27.4 0z',
        size: [40, 62]
      },
      'generic-industrial': {
        path: 'M54.36 43.969c.906 0 1.64.734 1.64 1.64v8.75A1.64 1.64 0 0 1 54.36 56H1.64A1.64 1.64 0 0 1 0 54.36v-8.75c0-.907.734-1.641 1.64-1.641zm-42.876 4.375h-3.28a1.64 1.64 0 1 0 0 3.281h3.28a1.64 1.64 0 1 0 0-3.281zm9.844 0h-3.281a1.64 1.64 0 1 0 0 3.281h3.281a1.64 1.64 0 1 0 0-3.281zm9.844 0H27.89a1.64 1.64 0 1 0 0 3.281h3.28a1.64 1.64 0 1 0 0-3.281zm10.062 0a1.64 1.64 0 1 0 0 3.28 1.64 1.64 0 0 0 0-3.28zm6.563 0a1.64 1.64 0 1 0 0 3.28 1.64 1.64 0 0 0 0-3.28zm4.922-11.047v3.39H3.28v-3.39H52.72zm1.64-15.313c.907 0 1.641.735 1.641 1.641v8.75a1.64 1.64 0 0 1-1.64 1.64H1.64A1.64 1.64 0 0 1 0 32.376v-8.75c0-.906.734-1.64 1.64-1.64zM11.484 26.36h-3.28a1.64 1.64 0 1 0 0 3.282h3.28a1.64 1.64 0 1 0 0-3.282zm9.844 0h-3.281a1.64 1.64 0 1 0 0 3.282h3.281a1.64 1.64 0 1 0 0-3.282zm9.844 0H27.89a1.64 1.64 0 1 0 0 3.282h3.28a1.64 1.64 0 1 0 0-3.282zm10.062 0a1.64 1.64 0 1 0 0 3.281 1.64 1.64 0 0 0 0-3.28zm6.563 0a1.64 1.64 0 1 0 0 3.281 1.64 1.64 0 0 0 0-3.28zm4.922-11.047v3.391H3.28v-3.39H52.72zM54.359 0C55.266 0 56 .734 56 1.64v8.75a1.64 1.64 0 0 1-1.64 1.641H1.64A1.64 1.64 0 0 1 0 10.391V1.64C0 .734.734 0 1.64 0zM11.484 4.375h-3.28a1.64 1.64 0 1 0 0 3.281h3.28a1.64 1.64 0 1 0 0-3.281zm9.844 0h-3.281a1.64 1.64 0 1 0 0 3.281h3.281a1.64 1.64 0 1 0 0-3.281zm9.844 0H27.89a1.64 1.64 0 1 0 0 3.281h3.28a1.64 1.64 0 1 0 0-3.281zm10.062 0a1.64 1.64 0 1 0 0 3.281 1.64 1.64 0 0 0 0-3.281zm6.563 0a1.64 1.64 0 1 0 0 3.281 1.64 1.64 0 0 0 0-3.281z',
        size: [56, 56]
      },
      handheld: {
        path: 'M59.132 0A2.87 2.87 0 0 1 62 2.867v25.266A2.87 2.87 0 0 1 59.132 31H21.031v1.329c0 .103.015.206.044.305.027.096 2.854 10.157-.044 18.69l-.052.12a12.982 12.982 0 0 0-.953 3.297c-.05.354-.073.71-.072 1.068l-.02 4.2a2 2 0 0 1-2 1.991H7.53a2 2 0 0 1-2-2v-4.05a9.673 9.673 0 0 0-.26-2.334 28.67 28.67 0 0 1-.244-1.116 26.239 26.239 0 0 0-.284-1.287L.516 34.643a1.375 1.375 0 0 0-.039-.125 6.944 6.944 0 0 1-.427-3.362c.7-5.452 3.493-12.062 5.48-16.233v17.51a5.367 5.367 0 0 0 1.683 3.897 4.84 4.84 0 0 0 3.61 1.328 4.987 4.987 0 0 0 4.672-4.97V31h5.536V18.314c.001-.794-.298-1.556-.833-2.136l-.152-.153a2.598 2.598 0 0 0-1.955-.733 2.768 2.768 0 0 0-2.596 2.768V31H11a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h48.132z',
        size: [62, 62]
      },
      lock: {
        path: 'M19.515 0C27.47 0 33.94 6.47 33.94 14.424v8.93a19.44 19.44 0 0 1 5.091 13.13C39.03 47.247 30.276 56 19.515 56 8.755 56 0 47.246 0 36.485a19.44 19.44 0 0 1 5.091-13.131v-8.93C5.091 6.471 11.561 0 19.515 0zM19.5 30A5.5 5.5 0 0 0 17 40.4v1.1a2.5 2.5 0 1 0 5 0v-1.1A5.5 5.5 0 0 0 19.5 30zm.015-24.91c-5.146 0-9.333 4.188-9.333 9.334v4.926a19.395 19.395 0 0 1 9.333-2.38c3.379 0 6.56.863 9.333 2.38v-4.926c0-5.146-4.186-9.333-9.333-9.333z',
        size: [40, 56]
      },
      pc: {
        path: 'M60 5.278v38.855a5.278 5.278 0 0 1-5.267 5.278H36.039v2.159a8.634 8.634 0 0 0 .583 3.238 3.67 3.67 0 0 0 1.155 1.683h6.238a1.78 1.78 0 0 1 1.727 1.188 1.76 1.76 0 0 1-1.662 2.32H16.017a1.792 1.792 0 0 1-1.748-1.198 1.76 1.76 0 0 1 1.673-2.31h6.303a3.788 3.788 0 0 0 1.133-1.64c.4-1.032.598-2.132.583-3.238v-2.159H5.278A5.278 5.278 0 0 1 0 44.176V5.321A5.28 5.28 0 0 1 5.278 0h49.455A5.278 5.278 0 0 1 60 5.278z',
        size: [60, 60]
      },
      router: {
        path: 'M19,3.87C22.06,13.73,22.5,24,24,34.16c.14,1,.13,2,.27,3,1,7.39,1,7.34,7.31,3.49a6.66,6.66,0,0,1,3.36-.58q18-.09,36-.07c3.47,0,7.73,5.18,7.42,10-.66,10.4,1.45,9.76-9.24,9.78-12.49,0-25,0-37.48,0-2.93,0-5.78.21-5.64-4.21.11-3.12-2.62-2.35-4.46-2.47-1.52-.1-3.2.95-3.63-1.83-2.58-16.41-5.11-32.82-6.28-49.4,0-.29.26-.6.41-.9h1c1.85,11.63,3.72,23.25,5.54,34.88.59,3.77,1,7.57,1.54,11.35.21,1.57.57,2.94,2.59,3a3.69,3.69,0,0,0,3.86-2.59c.79-2.4-1.44-1.4-2.42-1.73C22.54,45.4,22.93,44,22.79,43,21.51,33.45,20,23.93,19.24,14.35,19,10.92,17.16,7.38,19,3.87Z',
        size: [80, 67]
      },
      tv: {
        path: 'M55.414 45.91a2.256 2.256 0 0 1 0 4.511H11.921a2.256 2.256 0 0 1 0-4.512zM65.079 0a2.256 2.256 0 0 1 2.256 2.256v36.886a2.256 2.256 0 0 1-2.256 2.256H2.256A2.256 2.256 0 0 1 0 39.142V2.256A2.256 2.256 0 0 1 2.256 0z',
        size: [68, 51]
      },
    }
  }
};
