import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const DataBlock = ({content, gradient, vreduced, hreduced, help, additionalClass}) => {
  const classList = {
    'app-data-block': true,
    'background-gradient': !!gradient,
    'v-reduced': !!vreduced,
    'h-reduced': !!hreduced,
    'with-help': !!help
  };
  if (additionalClass) {
    classList[additionalClass] = true;
  }

  return (
      <div className={classNames(classList)}>
        {
          help && (
              <OverlayTrigger trigger="click" placement="left" rootClose overlay={<Tooltip id="help-tooltip">{help}</Tooltip>}>
                <div className="app-data-block__help d-flex align-items-center justify-content-center">
                  <span>i</span>
                </div>
              </OverlayTrigger>
          )
        }
        {content}
      </div>
  );
};

DataBlock.propTypes = {
  content: PropTypes.element.isRequired,
  gradient: PropTypes.bool,
  reduced: PropTypes.bool,
  help: PropTypes.string
};

export default DataBlock;
