import React from 'react';

const ProductSignUp = () => {
  const onSignUp = () => {
    // TODO
  };

  return (
      <div className="app-data-block__product-sign-up d-flex flex-column align-items-center">
          <div className="description text-center">For more details</div>
          <button className="sign-up-btn" onClick={onSignUp()}>Sign up</button>
      </div>
  );
};

export default ProductSignUp